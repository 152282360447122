import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/base.css";
//引入封装好的axios
import axios from "../utils/http.js";
import "lib-flexible/flexible.js"


import VueFullpage from 'vue-fullpage.js'
// 引入vant
import vant from 'vant';
import 'vant/lib/index.css';

import '../utils/rem';

// require styles 导入富文本编译器对应的样式
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'




createApp(App).use(ElementPlus).use(store).use(VueQuillEditor).use(VueFullpage).use(router).use(vant).use(axios).mount("#app");
