<template>






        <div class="btn-entry-all">

                <div class="close-icon-t" @click="clickClose" v-if="isDisplay">x</div>


                <div class="btn-entry" @click="clickContent"><img src="../assets/img/weix.png" alt="">

                        <div class="wx-content" v-if="isDisplay">

                                <div class="class-wx-title">

                                        <div class="class-text-title">

                                                <img src="../assets/img/cloudnative.jpg" alt="">
                                                <p>课程讲师: Jack</p>
                                        </div>
                                        <div class="class-text-name">Hi,想知道自己合适这门课程吗?<br>快联系我吧,为你定制专属的学习计划~</div>

                                </div>
                                <div class="class-wx-png"><img src="../assets/img/wx.png" alt=""></div>
                                <div class="class-wx-text">长按识别二维码添加老师为好友</div>
                        </div>

                </div>



        </div>


</template>

<script>

export default {

  data(){
    return {
            isDisplay: false
    }
  },
    methods: {
        clickContent (){

                if (this.isDisplay) {
                        this.isDisplay=false
                }else {
                        this.isDisplay=true
                }
        },
            clickClose(){
                this.isDisplay=false
            }
    }
};
</script>

<style scoped lang="scss">
                .close-icon-t {
                        cursor: pointer;
                        z-index: 100;
                        position: absolute;
                        right: 0%;
                        bottom: 111%;
                        width: 30px;
                        height: 30px;
                        color: #fff;
                        font-size: 26px;
                        line-height: 23px;
                        z-index: 1000;
                        bottom: 387px;
                }

        .wx-content {
                background-color: #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                right: 0%;
                bottom: 111%;
                width: 272px;
                height: 359px;
                -webkit-box-shadow: 0 15px 30px 0 rgba(0, 98, 90, .15);
                box-shadow: 0 15px 30px 0 rgba(0, 98, 90, .15);
                -webkit-border-radius: 30px;
                border-radius: 30px;
                -webkit-border-bottom-right-radius: 0;
                border-bottom-right-radius: 0;
                //border: 1px solid red;
                .class-wx-title {
                        background-color: #78c5d7;
                        border-top-left-radius: 30px;
                        border-bottom-left-radius: 30px;
                        border-top-right-radius: 30px;
                        //border: 1px solid red;
                        width: 100%;
                        height: 90px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        bottom: 11%;
                        .class-text-title {
                                margin-bottom: 5px;
                                width: 100%;
                                height: 30px;
                                display: flex;
                                flex-direction: row;
                                justify-content: left;
                                //border: 1px solid red;
                                img {
                                        width: 28px !important;
                                        border-radius: 4px !important;
                                        margin-left: 26px !important;
                                        margin-top: 3px;
                                        margin-bottom: 3px;
                                }
                                p {
                                        width: 90px;
                                        font-size: 12px;
                                        color: #fff;
                                        line-height: 30px;
                                        text-align: left;
                                        padding-left: 10px;
                                }

                        }
                        .class-text-name {
                                padding-left: 28px;
                                font-size: 13px;
                                color: #fff;
                                text-align: left;
                        }
                }
                .class-wx-text {
                        margin-top: 10px;
                        height: 30px;
                        width: 100%;
                       text-align: center;
                        color: #78c5d7!important;
                        padding-left: 8px;
                }
                .class-wx-png {
                        display: flex;
                        justify-content: center;
                        height: 150px;
                        width: 100%;

                        img{
                                margin-top: 0px!important;
                                width: 150px;
                        }

                }
        }

        .btn-entry-all {
                cursor: pointer;
                z-index: 100;
                position: fixed;
                left: 88%;
                top:78%;
                width: 60px;
                height: 60px;
                display: flex;
                flex-direction: row;
        }

    .btn-entry {
            position: relative;
        width: 100%;
        height: 60px;
        background: #78c5d7;
        color: #f1f1f1;
        -webkit-border-radius: 30px;
        border-radius: 30px;
            img {
                    align-content: center;
                    width: 30px;
                    margin-top: 14px;
                    margin-left: 15px;
            }
    }
</style>
