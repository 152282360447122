<template>
    <div class="about">

        <Head :AboutView="true"></Head>
        <div class="bg-img">
            <div class="bg-z">

                <div class="yingz"></div>
                <el-carousel height="360px" :interval="3000" class="el-cls">
                    <el-carousel-item v-for="item in carouseData" :key="item">
                        <img :src="item.url" alt=""  style="border-radius: 5px;"/>

<!--                        <div class="photo-title">-->
<!--                            <div class="copywriting-o">{{ item.title }}！</div>-->
<!--                            <div class="copywriting-t">-->
<!--                                <div class="copywriting-center">-->
<!--                                    <div class="copywriting-text">开放共赢</div>-->
<!--                                    <div class="copywriting-text">选择多样</div>-->
<!--                                    <div class="copywriting-text">盈利高效</div>-->
<!--                                    <div class="copywriting-text">体验流畅</div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <!-- 分类 -->
        <div class="class-a">

            <div class="cls-title">课程特色</div>
            <div class="cls-content">我们一起学习开发云原生应用</div>
            <div class="class-flex" >
                <div class="class-flex-cont" v-for="item in copyDataList" :key="item">
                    <img :src="item.url" alt="">
                    <p class="intro-title">{{ item.title }}</p>
                    <p class="intro-content">{{ item.text }}</p>
                </div>
            </div>
        </div>
        <div class="copywriting">
            <div class="con-s">精选课程</div>
            <div class="con-z">优质精选自学课程，为你打造技术知识宝库</div>
            <div class="margin-center">
                <div class="text-copy">
                    <div class="copywriting-box" v-for="(item,index) in copywritingList"
                        :key="index">
                        <img src="../assets/img/live.gif" alt="" class="cls-live">
                        <img class="copywriting-img" :src="item.url" alt="">
                        <div>
                            <div class="copy-title">{{ item.title }}</div>
                            <div class="copy-text">{{ item.text }}</div>
                            <div class="copy-foot"><span>{{ item.people }}人</span><span>{{ item.hours }}小时</span><span>{{ item.course }}节课</span></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="copywriting">
            <div class="con-s">学员评价</div>
            <div class="con-z">学员的认可就是我们前进的最大动力</div>
            <div class="margin-center">
                <div class="text-copy">
                    <div class="copywriting-box" v-for="item in copyCommentList" :key="item">
                        <div class="comment-item-inner">
                            <div class="comment-1"><img src="../assets/img/shuangyi.png" alt=""></div>
                            <div class="comment-content"> {{ item.text}}</div>
                            <div class="comment-2"><img src="../assets/img/shuangyi.png" alt=""></div>
                            <div class="comment-foot">
                                <img :src="item.url" alt="">
                                <p>{{ item.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyfoot">
            <div class="con-s">学习</div>
            <div class="con-z">你可以永久在线学习我们提供的课程</div>
            <div class="con-t"> <el-button type="primary" style="color:white;" color="#78c5d7">
                现在就去开始学习 &nbsp; >
            </el-button></div>

        </div>

        <div class="foot">
            <Footer></Footer>
        </div>
        <Content></Content>
    </div>
</template>
  <script>
import Head from '../components/Head'
import Footer from '../components/Footer'
import Content from '../components/Content.vue'

export default {
    components: {
        Head,
        Content,
        Footer
    },
    data () {
        return {

            carouseData: [
                { url: require("../assets/img/f3.jpg"), title: "商机无限1", contact: "自定义内容1" },
                { url: require("../assets/img/f2.jpg") , title: "商机无限2", contact: "自定义内容2"},
                { url: require("../assets/img/f1.jpg"), title: "商机无限3", contact: "自定义内容3" },
            ],
            copyDataList: [
                {
                    url: require("../assets/img/dev.png"),
                    title: '全栈开发',
                    text: '学习使用 HTML/CSS/JavaScript, Python/Golang 来创建完整的 WEB 应用',
                },
                {
                    url: require("../assets/img/project.png"),
                    title: '技术前沿',
                    text: '掌握最新最前沿技术，Docker、Kubernetes、Istio、Golang、Flutter、Serverless 一网打尽',
                },
                {
                    url: require("../assets/img/write.png"),
                    title: '项目实战',
                    text: '不只是基础学习，还会有实战项目演练，让你在项目中去掌握技术',
                },
            ],
            copywritingList: [
                {
                    url: require("../assets/img/q1.36453d2.png"),
                    title: '容器技术',
                    text: '容器是一种沙盒技术，主要目的是为了将应用运行在其中，与外界隔离；及方便这个沙盒可以被转移到其它宿主机器。',
                    people: 10,
                    hours: 9,
                    course: 30,
                },
                {
                    url: require("../assets/img/q3.2732302.png"),
                    title: 'Kubernetes',
                    text: 'Kubernetes 也称为 K8s，是用于自动部署、扩缩和管理容器化应用程序的开源系统。',
                    people: 10,
                    hours: 9,
                    course: 30,
                },
                {
                    url: require("../assets/img/q4.eb667a4.png"),
                    title: '微服务',
                    text: '微服务就是把一个项目拆分成独立的多个服务，并且多个服务是可以独立运行的，而每个服务都会占用线程。',
                    people: 10,
                    hours: 9,
                    course: 30,
                },
                {
                    url: require("../assets/img/q2.b2b1669.png"),
                    title: 'Golang',
                    text: 'Go语言是由 Google 的 Robert Griesemer,Rob Pike 及 Ken Thompson 开发的一种静态强类型、编译型语言。',
                    people: 10,
                    hours: 9,
                    course: 30,
                },
            ],
            copyCommentList: [
                {
                    url: require("../assets/img/people-1.png"),
                    text: '课程由浅到深。 对官方文档和k8s理解非常深刻 涵盖了很多工作中直接可以使用到的知识点与实战经验。 非常值得希望深入了解k8s同学，细细品味和学习！',
                    name: '黑夜的灯',
                },
                {
                    url: require("../assets/img/people-2.png"),
                    text: '老师是非常务实的老师，知识是循序渐进娓娓道来，毫不拖泥带水，也没有一丝的废话，直接切入重点，让我们由浅入深对docker以及k8s有了非常深入的了解，不仅如此，老师还在微信群里不厌其烦的解释我们遇到的各种问题，这样的优质讲师真心不错，最最重要的课程内容物超所值，你一定不会后悔！',
                    name: '暗夜精灵',
                },
                {
                    url: require("../assets/img/people-1.png"),
                    text: '内容有浅入深，循序渐进，适合各阶段的容器爱好者，我是从k8s开始学习的，醍醐灌顶，之前很多疑惑都解决了，现在轻松大家k8s集群，大部分服务都运行在容器中，持续迭代开发，效率提高了不止一倍。',
                    name: 'Jack',
                },
                {
                    url: require("../assets/img/people-2.png"),
                    text: '老师的课，绝对物超所值，比起某些培训机构好几千的培训费用，买老师的课就是赚了好几千的感觉... 关键是老师的课非常重视实战，课程把复杂的K8S讲的让你感觉原来容器和容器管理如此简单！值了！另外老师课程之外在群里答疑也非常认真而且及时，感谢老师，辛苦了！',
                    name: 'Frank',
                },
            ],
        }
    },
    created(){
        document.body.scrollTop = 0
    },
    methods: {
        skipClass (type) {
            this.$router.push({
                name: 'classDetail',
                query: {type: type}
            })
        },
        settlingProcess () {
            this.$router.push({ name: 'about' })
        },
        tariff (){
            this.$router.push({
                name: 'packCoupon',
            })
            
        }
    },
}
  </script>

<style lang="scss">
    .el-carousel__button {background-color: #78c5d7!important;height: 10px!important;width: 10px!important;border-radius: 10px}
</style>

  <style scoped lang="scss">

  .el-cls {
      margin-top: 50px;
  }

.about {
    //position: relative;
    width: 100%;
    background-color: #fff;
}


.bg-img {
    display: flex;
    justify-content: center;
    //background-image: url(https://supplychainimg.gdsupplychain.com/other/bg1.png);
    background-size: 100% 100%;
    width: 100%;
    height: 400px;
    margin-top: 39px;
    position: relative;
    border-radius: 5px;

    .bg-z {
        position: relative;
        width: 1176px;
        height:100%;
        //background-image: url(https://supplychainimg.gdsupplychain.com/other/bg1.png);
        background-size: 100% 100%;
        border-radius: 5px;

        .yingz {
            background: linear-gradient(to right bottom, rgba(61, 96, 96, 0.08), rgba(219, 231, 231, 0.01) 50%, rgba(219, 231, 231, 0.01));
            top: 26%;
            position: absolute;
            width: 1064px;
            height: 300px;
            transform: translate(10px, 100%) skewX(45deg) scaleY(1);
            left: 8%;
        }
    }

    .photo-title {
        position:absolute;
        z-index:1000;
        bottom:59%;

        .copywriting-o {
            font-size: 60px;
            color: #fff;
            padding-top: 68px;
            padding-left: 5%;
        }
        @media only screen and (min-width: 1366px) and (max-width:1760px)  {
            .copywriting-o {
                padding-left: 5%;
            }
        }


        .copywriting-t {
            width: 60%;
            font-size: 50px;
            color: #fff;
            margin-left: 41px;
            padding-left: 18%;

            .copywriting-center {
                display: flex;
                width: 760px;
                font-size: 27px;
                margin-top: 11px;
                .copywriting-text {
                    width: 125px;
                    border: 2px solid #fffffb;
                    border-radius: 10px;
                    text-align: center;
                    margin-right: 5px;
                    line-height: 36px;

                }
            }
        }
        @media only screen and (min-width: 1366px) and (max-width:1760px)  {
            .copywriting-t {
                padding-left: 3%;
            }
        }
    }




    //background-image: url(https://supplychainimg.gdsupplychain.com/other/bg1.png);
    //background-size: 100% 100%;
    //width: 100%;
    //height: 400px;
    //margin-top: 91px;
    //position: relative;
    //
  
    

}
.class-a {
    margin-top: 30px;
    max-height: 358px;
    height: 350px;
    width: 100%;
    background-color: #f7f9fc;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    .cls-title {
        margin-top: 30px;
        float: left;
        text-align: center;
        font-weight: 600;
        font-size: 27px;
    }
    .cls-content {
        text-align: center;
        margin-top: 10px;
        font-size: 18px;
    }
    .class-flex {
        display: flex;
        justify-content: center;

    }
    .class-flex-cont {
        margin-left: 35px;
        width: 300px;
        height: 165px;
        padding: 40px;
        img{
            margin-left: 123px;
            vertical-align: middle;
            width: auto;
            height: 66px;
        }
        .intro-title {
            text-align: center;
            margin: 15px 0;
            font-weight: 600;
            font-size: 20px;
        }
        .intro-content{
            text-align: center;
            font-size: 14px;
            font-family: PingFang SC, Helvetica Neue, "Monospaced Number", Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Hiragino Sans GB, Microsoft YaHei, Helvetica, Arial, sans-serif;
        }
    }
}
.aptitude {
    margin-top: 36px;
    margin-bottom: 42px;
    display: flex;
    justify-content: center;
    
    .photo-o {
        width: 750px;
        height: 240px;
    }
    .enter {
    cursor: pointer;

        width: 593px;
        .enter-left {
            margin-left: 31px;
        }
    }
}
.copyfoot {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 20px;
    text-align: center;
    background: #f7f9fc;
    .con-t {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        //border: 1px solid red;
    }
    .con-z{
        height: 30px;
        width: 100%;
        font-size: 18px;
        text-align:center;
        margin-bottom: 10px;
    }
    .con-s {
        font-weight: 600;
        font-size: 27px;
        text-align: center;
        padding-top: 30px;
        margin-bottom: 5px;
    }
}
.copywriting {
    background-color: #ffffff;
    padding-bottom: 34px;
    .con-z{
        height: 30px;
        width: 100%;
        font-size: 18px;
        text-align:center;
        margin-bottom: 10px;
    }
    .con-s {
        font-weight: 600;
        font-size: 27px;
        text-align: center;
        padding-top: 10px;
        margin-bottom: 5px;
    }
    .margin-center {
        height: auto;
        justify-content: center;
        display: flex;
        .text-copy {
            height: auto;
            width: 1200px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            .copywriting-box {
                position: relative;
                margin-top: 20px;
                margin-right: 20px;
                width: 274px;
                background: #ffffff;
                box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.1);
                border-radius: 7px;
                //border: 1px solid red;
                .copywriting-img {
                    max-height:150px;
                    width: 100%;
                    border-radius: 7px 7px 0 0;
                }
                .copy-title {
                    font-weight: 600;
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 25px;
                    text-align: left;
                    margin-top: 13px;
                    padding-left:21px;
                }
                .copy-text {
                    min-height: 60px;
                    max-height: 60px;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 19px;
                    padding: 10px 24px 17px 24px;
                }
                .copy-foot {
                    height: 30px;
                    width: 100%;
                    padding: 10px 24px 17px 24px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 24px;
                    color: #828282;
                    span {
                        padding-left:5px;
                    }
                }
                .comment-item-inner {
                    padding-bottom: 5px;
                    .comment-foot {
                        margin-bottom: 15px;
                        display: flex;
                        flex-direction: row;
                        img {
                            margin-left: 10px;
                            width: 28px;
                            height: 33px;
                        }
                        p {
                            line-height: 38px;
                            padding-left: 9px;
                        }
                    }
                    .comment-1 {
                        img {
                            margin-top: 10px;
                            float: left;
                            margin-left: 10px;
                        }
                    }
                    .comment-2 {
                        img {
                            float: right;
                            margin-right: 10px;
                        }
                    }
                    .comment-content {
                        min-height: 161px;
                        padding: 10px;
                        line-height: 20px;
                        font-size: 13px;
                        color: #58696c;
                    }
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 300px;
                    letter-spacing: 0.5px;
                    img{
                        width: 30px;
                    }
                }

                .cls-live {
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    left: 93%;
                    border-top-right-radius: 5px;
                    top: 4px;
                }

            }
        }
    }
}
.connection {
    .con-t {
        font-size: 36px;
        text-align: center;
        padding-top: 30px;
    }
    .user {
        height: 404px;
        margin-top: 45px;
        display: flex;
        justify-content: center;
        .user-o {
            width: 227px;
            height: 286px;
            background: #ffffff;
            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            text-align: center;
            .imgSize {
                width: 121px;
                height: 121px;
                text-align: center;
                line-height: 28px;
            }
            .cart-title {
                height: 30px;
                margin-top: 17px;
                display: flex;
                .color-r {
                    width: 4px;
                    height: 20px;
                    background-color: #3b8dff;
                    margin: 0 10px 0 22px;
                }
                .color-title {
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    line-height: 20px;
                }
            }
            .info {
                height: 50px;
                margin-top: 17px;
                .info-name {
                    display: flex;
                    .info-img {
                        width: 11px;
                        height: 13px;
                        margin: 6px 6px 0 14px;
                    }
                    .name-i {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                    }
                }
                .info-name-a {
                    margin-top: 13px;
                }
            }
        }
        .user-t {
            margin-left: 200px;
        }
        .user-s {
            margin-left: 26px;
        }
    }
}

.bg-img .index-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg-img .index-content h1 {
    font-size: 80px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
}

.bg-img .index-content h5 {
    width: 967px;
    height: 102px;
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 63px;
}

.index-content .role-entrance {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 960px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid hsla(0, 0%, 100%, 0.5);
    background: hsla(0, 0%, 100%, 0.3);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    margin-top: 70px;
}

.index-content .role-entrance .role-item:hover {
    background-color: #04b940;
    border-radius: 10px;
}

.index-content .role-entrance .role-item:hover ~ .item-div {
    display: none;
}

.index-content .role-entrance .item-div {
    width: 1px;
    height: 86px;
    background: #ffffff;
}

.index-content .role-entrance .role-item {
    position: relative;
    box-sizing: border-box;
    height: 120px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.index-content .role-entrance .role-item:hover .item-code {
    display: block;
}

.index-content .role-entrance .role-item .item-code {
    width: 238px;
    position: absolute;
    top: 143px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1001;
    display: none;
}

.index-content .role-entrance .role-item .item-code img {
    width: 238px;
    height: 253px;
}

.index-content .role-entrance .role-item p {
    width: 238px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
    margin-top: 20px;
    text-align: center;
}

.index-content .role-entrance .role-item .info {
    color: #fff;
}

.index-content .role-entrance .role-item .info .title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
}

.index-content .role-entrance .role-item .info .subtitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
}

.index-content .role-entrance .role-item .icon {
    width: 19px;
    height: 36px;
    margin-left: 100px;
}

.foot {
    width: 100%;
    height: 150px;
    background: #192035;
}
.foot .list {
    width: 1440px;
    height: 153px;
    margin-top: 125px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.foot .list .list-content {
    height: 153px;
}
.foot .list .list-content ul {
}
.foot .list .list-content ul li {
    margin-bottom: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
}
.foot .list .list-content ul li h1 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin-top: 0;
}
.foot .list .list-content ul li:nth-child(5) {
    margin-bottom: 0;
}
.foot .list .list-code {
    display: flex;
    align-items: center;
}
.foot .list .list-code .code-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 53px;
}
.foot .list .list-code .code-data img {
    width: 104px;
    height: 104px;
}
.foot .list .list-code .code-data span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-top: 16px;
}
.foot > p {
    margin-top: 85px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
}



</style>
