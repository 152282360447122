/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
import QS from "qs";
import md5 from "./md5.js";
import util from "./util.js";
import router from "../src/router/index.js";
import store from '@/store'
//qs.stringify()是将对象 序列化成URL的形式，以&进行拼接
//  let protocol = window.location.protocol; //协议
//  let host = window.location.host; //主机
//  axios.defaults.baseURL = protocol + "//" + host;
// 创建签名
const createSign = (config) => {
  var timestamp = new Date().getTime();
  config.headers.timestamp = timestamp;
  let object = {
    contentType: "json",
    method: config.method == "UPLOAD" ? "POST" : config.method,
    timestamp: timestamp + "",
    source: localStorage.getItem("os") || "ios",
    SECRET_KEY: "serkhas&&23@@#hqw123e",
  };
  return md5.hex_md5(JSON.stringify(object).toUpperCase());
};

axios.defaults.baseURL = "https://tuanapi.hostscc.com/api"; //正式
// axios.defaults.baseURL = "https://m.hostscc.com/tuanApi"; //测试
// //设置超时
axios.defaults.timeout = 50000;

axios.interceptors.request.use(
  //响应拦截
  async (config) => {
    if (!localStorage.getItem("uuid")) {
      var uuid = util.generateRandomId();
      localStorage.getItem("uuid", uuid);
    } else {
      var uuid = localStorage.getItem("uuid");
    }
    var oaid = localStorage.getItem("oaid");

    const data = {};

    const system = localStorage.getItem("os");
    const platform = localStorage.getItem("os");

    // 实例请求对象并全局配置
    config.headers = {
      ...config.headers,
      ...data,
      uuid,
      oaid,
      extInfo: {
        model: "",
        brand: "",
      },
      "api-ver": "v3",
      channel: localStorage.getItem("channel") || "",
    };
    // 注入token console.log(this.$store.state.name);
    var token = store.state.token || localStorage.getItem("token") || "";
    if (token) config.headers.token = token;
    // 注入来源平台
    var source = localStorage.getItem("os") || "ios";
    if (source) config.headers.source = source;

    // 注入签名
    config.headers.sign = createSign(config);
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // config.header.authorization = sessionStorage.getItem("Token");
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response); //进行中
    } else {
      return Promise.reject(response); //失败
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          break;
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          sessionStorage.clear();
          router.push("/login");
          break;
        // 404请求不存在
        case 404:
          break;
        // 其他错误，直接抛出错误提示
        default:
      }
      return Promise.reject(error.response);
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $get = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const $post = (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params)) //是将对象 序列化成URL的形式，以&进行拼接
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};
//下面是vue3必须加的，vue2不需要，只需要暴露出去get，post方法就可以
export default {
  install: (app) => {
    app.config.globalProperties["$get"] = $get;
    app.config.globalProperties["$post"] = $post;
    app.config.globalProperties["$axios"] = axios;
  },
};
