<template>
    <div class="foots">
        <div class="box">
            <div class="box-text box-f-title"><img src="../assets/img/cloudnative.jpg" alt=""><span>云原生社区</span></div>
            <div class="box-text box-f-content">© 2024 新橙  <span>版权所有</span> <a class="a-color" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">粤ICP备20241312</a></div>
        </div>

        <div class="box-right">
            <div class="box-rtext">
                <a href="www.hostscc.com">建议反馈</a>
                <a href="www.hostscc.com">公众号</a>
                <a href="www.hostscc.com">服务号</a>
            </div>
        </div>
    </div>
</template>

<script>
</script>

<style scoped lang="scss">
.foots{
    justify-content: space-between;
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .box-right {
        height:100%;
        width: 400px;
        text-align: left;
        //border: 1px solid red;
        margin-top: 95px;
        .box-rtext {
            width: 100%;
            height: 30px;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            a {
                font-weight: bold;
                display: block;
                width: 80px;
                color: white;
                line-height: 20px;
            }
        }
    }
    .box {
        .box-f-content {
            a {
                color: #78c5d7!important;
                padding-left: 5%;
            }
        }
        .box-f-title {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            img {
                border-radius: 10px;
                width: 30px;
                height: 30px;
            }
            span{
                padding-left: 10px;
                font-size: 16px;
            }
        }
        padding-left: 20px;
        height:100%;
        width: 400px;
        text-align: left;
        margin-top: 55px;
        .box-text {
            width: 100%;
            line-height: 30px;
            height: 30px;
            color:#9fa2aa;
            font-size: 14px;
            .a-color {
                color: #9fa2aa;
            }
        }
    }
}
.foots>p{
    margin-top: 85px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.foots .list{
    width: 1440px;
    height: 153px;
    margin-top: 125px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.foots .list .list-content{
    height: 153px;
}
.foots .list .list-content ul{

}
.foots .list .list-content ul li{
    margin-bottom: 15px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.foots .list .list-content ul li h1{
    font-size: 20px;
    font-family: Microsoft YaHei;
  font-weight: bold;
    color: #FFFFFF;
    margin-top: 0;
}
.foots .list .list-content ul li:nth-child(5){
    margin-bottom: 0;
}
.foots .list .list-code{
    display: flex;
    align-items: center;
}
.foots .list .list-code .code-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 53px;
}
.foots .list .list-code .code-data img{
    width: 104px;
    height: 104px;
}
.foots .list .list-code .code-data span{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 16px;
}
</style>
