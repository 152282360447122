import { createRouter, createWebHistory,createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/kubernetes",
    name: "kubernetes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KubernetesView.vue"),
  },
  {
    path: "/golang",
    name: "golang",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Golang.vue"),
  },
  {
    path: "/springcloud",
    name: "springcloud",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SpringCloud.vue"),
  },
  {
    path: "/ClassDetail",
    name: "classDetail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ClassDetail.vue"),
  },
 

];



const router = createRouter({
  history: createWebHashHistory(''),
  routes,
  linkActiveClass: 'is-active'
});


export default router;
